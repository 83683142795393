import { InjectionToken } from '@angular/core';
import type { Bar } from 'charting-library';

export type ChartResolution = '1S' | '1' | '3' | '5' | '15' | '30' | '60' | '120' | '240' | '720' | '1440' | '4320' | '10080' | '43200';
export type ChartMinuteBasedResolution = Exclude<ChartResolution, '1S'>;

export type ChartTokenRef = 'usd' | 'eth';
export type ChartIndicator = 'price' | 'mcap';

export interface ChartTokenData {
  symbol: string;
  isStableSymbol: boolean;
  created: number; // in milliseconds
  supply?: number;
}

export const MIN_RESOLUTION: ChartResolution = '1S'; // 1 second
export const MIN_MINUTE_RESOLUTION: ChartResolution = '1'; // 1 minute
export const MAX_RESOLUTION: ChartResolution = '1440'; // 1 day
export const SUPPORTED_API_RESOLUTIONS: ChartResolution[] = [MIN_RESOLUTION, MIN_MINUTE_RESOLUTION, '15', MAX_RESOLUTION];
export const SUPPORTED_API_RESOLUTIONS_V2: ChartResolution[] = [
  MIN_RESOLUTION,
  MIN_MINUTE_RESOLUTION,
  '3',
  '5',
  '15',
  '30',
  '60',
  '120',
  '240',
  '720',
  MAX_RESOLUTION,
];

export interface RawCandlesWithRequestsData {
  requests: CandlesRequestParams[];
  rawCandles: RawCandles;
}

export interface CandlesRequestParams {
  startTs: number;
}

export interface RawCandlesWithRequestsDataV2 {
  requestedSpan?: CandleTimeSpan;
  rawCandles: RawCandles;
}

export interface RawCandles {
  version?: number; // cache version in API
  symbol: ChartTokenRef;
  candles: Candle[];
  oldestTs?: number; // oldest candle available in API
  nextTs?: number;
  firstTs?: number;
}

export interface ApiRawCandles {
  symbol: ChartTokenRef;
  candles: Candle[];
  next?: NextCandles;
  first?: number; // oldest candle on array
}

export interface NextCandles {
  ts: number;
  span: CandleTimeSpan;
  res: ChartResolution;
}

export interface OldestCandlesDate {
  oldestCandle: OldestCandles;
}

export interface OldestCandles {
  date: string;
  timestamp: number;
}

export interface Candle extends Bar {
  time: number; // NOTE: time is in millis (swaps timestamp is in seconds)
  volume: number;
  lastTimestamp?: number; // The last timestamp swap in the candle (in seconds);
  firstTimestamp?: number; // The first timestamp swap in the candle (in seconds);
}

export type Candles = {
  [key in ChartTokenRef]: CandlesIndicator;
};

export type CandlesIndicator = {
  [key in ChartIndicator]: Candle[];
};

export type CandleTimeSpan = '3h' | '6h' | 'day' | 'week' | 'month';

export interface SuitableTimeSpan {
  suitableTimeSpan: CandleTimeSpan;
  suitableTimeSpanMs: number;
}

export interface CandleTransaction {
  timestamp: number; // millis
  price: number;
  priceETH: number;
  amountETH: number;
  amountToken: number;
}

export interface MaxMin {
  max: number;
  min: number;
}

export type MaxMinIndicator = {
  [key in ChartIndicator]: MaxMin;
};

export type MaxMinPrices = {
  [key in ChartTokenRef]: MaxMinIndicator;
};

export interface ChartConfig {
  backgroundLight: string;
  backgroundDark: string;
}

export const CHART_CONFIG = new InjectionToken<ChartConfig>('ChartConfig');
